<template>
  <PageWithLayout
    :topPadding="88"
    :isBodyFull="true"
    :isBackBtn="true"
    backRouteName="WalletMainParents"
    :isBackWebEnd="$route.query.isBackWebEnd && $route.query.isBackWebEnd === 'true'"
    :isTitle="true"
    :title="$poData.coupon_box_title">
    <div class="area_fixed_under_header">
      <Input
        :placeholder="$poData.coupon_box_search"
        :value.sync="viewModel.couponRegisterValue"
        valueType="promotionCode"
        :isBlockUnValueType="true"
        @onEnterKey="viewModel.couponRegister()"/>
      <Button
        class="btn_register"
        btnStyle="primary"
        :text="$poData.coupon_box_register_btn"
        @onClickBtn="viewModel.couponRegister()" />
    </div>
    <div
      v-if="viewModel.couponList.length > 0"
      class="list_coupon">
      <div
        class="box_coupon"
        v-for="(itemData, index) in viewModel.couponList"
        :key="`couponItem${index}`">
        <div class="area_overflow">
          <span class="txt_day" v-if="getLeftDate(itemData.end) >= 0">{{ getLeftDate(itemData.end) }}일 남음</span>
          <div class="area_overflow">
            <Badge
              value="자녀용"
              type="lightPurple"/>
            <strong class="tit_coupon">{{ itemData.name }}</strong>
          </div>
        </div>
        <p class="desc_point">{{ itemData.point | convertNumberToComma(false,'0','원') }} 포인트</p>
        <p class="desc_end">{{ itemData.end | convertDateFormat('YYYY.MM.DD') }} 까지</p>
        <div class="area_overflow">
          <p class="desc_info">본인사용 불가<br>자녀에게 선물하기만 가능</p>
          <Button
             v-if="getLeftDate(itemData.end) >= 0"
            text="선물하기"
            btnSize="small"
            btnStyle="secondary_tonal"
            @onClickBtn="viewModel.onClickGift(itemData)"/>
        </div>
      </div>
    </div>
    <template v-else>
      <p class="desc_empty">{{ $poData.coupon_box_empty }}</p>
    </template>
    <template v-slot:popup>
      <CouponGiftDetail 
        v-if="viewModel.giftCouponData.name"
        :couponData="viewModel.giftCouponData"
        @getCouponList="viewModel.getCouponList()"
        @onClickClose="viewModel.onClickCouponGiftDetailClose()"/>
    </template>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from '@/components/layout/PageWithLayout';
import Input from '@/components/common/input/Input';
import Badge from '@/components/common/badge/Badge';
import Button from '@/components/common/button/Button';
import CouponGiftDetail from '@/views/coupon/view/CouponGiftDetail';

import { dateDiff } from "@/utils/dateUtils"
// mixins
import BackKeyEventMixin from '@/mixins/BackKeyEventMixin';
// viewModel
import CouponListViewModel from '@/views/coupon/viewModel/CouponListViewModel'

export default {
  name: 'CouponList',
  mixins:[ BackKeyEventMixin ],
  components:{
    PageWithLayout,
    Input,
    Badge,
    Button,
    CouponGiftDetail
  },
  beforeMount(){
    this.viewModel.init();
  },
  data(){
    return{
      viewModel: new CouponListViewModel(),
    }
  },
  methods:{
    getLeftDate(endDate){
      return dateDiff(endDate, new Date());
    },
    onClickBackKey(){
      if(this.viewModel.giftCouponData.name){
        this.viewModel.onClickCouponGiftDetailClose()
      }
      else{
        this.$router.push({ name: 'WalletMainParents' });
      }
    }
  }
}
</script>

<style scoped>
.desc_empty{position:absolute;top:50%;left:0;margin-top:-24px;width:100%}

.list_coupon{padding:20px}
.list_coupon .box_coupon{padding:16px;border-radius:4px;background-color:#fff}
.list_coupon .box_coupon + .box_coupon{margin-top:20px}

.list_coupon .box_coupon .badge_comm{float:left}
.list_coupon .box_coupon .tit_coupon{display:block;overflow:hidden;padding:4px 0 4px 8px;font-weight:500;font-size:12px;line-height:16px;color:#333;white-space:nowrap;text-overflow:ellipsis}
.list_coupon .box_coupon .txt_day{float:right;padding:4px 0 4px 8px;font-weight:500;font-size:12px;line-height:16px;color:#F98E5A}

.list_coupon .box_coupon .desc_point{margin-top:12px;font-weight:700;font-size:24px;line-height:30px;color:#8F69DD}
.list_coupon .box_coupon .desc_end{margin-top:12px;font-weight:500;font-size:12px;line-height:16px;color:#666}
.list_coupon .box_coupon .desc_end + .area_overflow{margin-top:12px}

.list_coupon .box_coupon .desc_info{float:left;font-weight:500;font-size:12px;line-height:16px;color:#999}
.list_coupon .box_coupon .btn_small{float:right}

.btn_register{display:none}
.tf_valued.tf_focus + .btn_register,
.tf_valued + .btn_register{display:block;position:fixed;left:0;right:0;bottom:0;border-radius:0}
</style>