import { GET_REQUEST, POST_REQUEST } from '@/services/http';
import { makeQueryStringByObject } from '@/utils/urlUtils';
import apiPath from '@/services/apiPath';
import store from '@/store/index';
import poData from '@/constants/poData.js';

export default class CouponGiftDetailViewModel {
  constructor() {
    this.couponData = {};
    this.child_list = [];
    this.child_current_count = [];
    this.selectedChild = '';
    this.onClosePopup = undefined;
    this.getCouponList = undefined;
  }
  init(couponData){
    this.couponData = couponData;
    this.getFamilyMemberList();
  }
  onClickSeletedChild(uid){
    this.selectedChild = uid
  }
  onClickGiftApply(){
    this.postCouponApply()
  }
  getFamilyMemberList(){
    const path = `${apiPath.FAMILY_MEMBER_LIST}`;
    GET_REQUEST(path).then(
    (success) => {
      const resultData = success.data.body;
      this.child_list = resultData.child_list;
      this.child_current_count = resultData.child_current_count;
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
  postCouponApply(){
    const data = {
      childUserUid : this.selectedChild,
      couponCodeId : this.couponData.id
    }
    const path = `${apiPath.COUPON_APPLY}${makeQueryStringByObject(data)}`;
    POST_REQUEST(path).then(
    (success) => {
      const resultData = success.data.body;
      store.dispatch('commonToast/fetchToastStart',
      {
        msg : poData.gift_join_coupon_success_toast,
        type: 'success'
      });
      this.getCouponList();
      this.onClosePopup()
    }, (failed) => {
      // store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
}