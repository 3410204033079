import { GET_REQUEST, POST_REQUEST } from '@/services/http';
import { makeQueryStringByObject } from '@/utils/urlUtils';
import apiPath from '@/services/apiPath';
import store from '@/store/index';
import poData from '@/constants/poData.js';

export default class CouponListViewModel {
  constructor() {
    this.couponRegisterValue = '';
    this.couponList = [];
    this.giftCouponData = {};
  }
  init(){
    this.getCouponList();
  }

  couponRegister(){
    this.postCouponRegister();
  }

  onClickGift(giftCouponData){
    this.giftCouponData = giftCouponData;
  }
  onClickCouponGiftDetailClose(){
    this.giftCouponData = {};
  }

  postCouponRegister(){
    const data = {
      name : this.couponRegisterValue
    }
    const path = `${apiPath.COUPON_REGISTER}${makeQueryStringByObject(data)}`;
    POST_REQUEST(path).then(
    (success) => {
      const resultData = success.data.body;
      this.couponRegisterValue = '';
      store.dispatch('commonToast/fetchToastStart',
      {
        msg : poData.register_redeem_success_toast,
        type: 'success'
      });
      this.couponList = resultData;
    }, (failed) => {
      // store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
  getCouponList(){
    const path = `${apiPath.COUPON_LIST}`;
    GET_REQUEST(path).then(
    (success) => {
      const resultData = success.data.body;
      this.couponList = resultData;
    }, (failed) => {
      // store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
}