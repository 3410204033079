<template>
  <Layer
    class="page_detail"
    :isFull="true"
    @onClickClose="$emit('onClickClose')">
    <p class="desc_guide" v-html="$poData.gift_join_coupon_details_title"></p>
    <div class="box_coupon">
      <p class="desc_point">{{ viewModel.couponData.point | convertNumberToComma(false,'0','원') }}</p>
      <div class="area_overflow">
        <Badge
          value="자녀용"
          type="lightPurple"/>
        <strong class="tit_coupon">{{ viewModel.couponData.name }}</strong>
      </div>
    </div>
    <div
      v-if="viewModel.child_current_count > 0"
      class="list_child">
      <ListItem
        v-for="(itemData, index) in viewModel.child_list"
        :key="`childItem${index}`"
        :class="{ 
          'item_selected' :  itemData.user.user_uid === viewModel.selectedChild,
          'item_disabled' :  itemData.user.status === 'PENDING',
        }"
        :padding="itemData.user.status === 'PENDING' ?  15 : 23"
        :thumbNail="{
          size: 40,
          url : itemData.user.profile_image_url
        }"
        :title="itemData.family_member.title"
        :leftSubText="itemData.user.status === 'PENDING' ? $poData.gift_join_coupon_details_not_login_child : ''"
        @onClickItem="itemData.user.status != 'PENDING' ? viewModel.onClickSeletedChild(itemData.user.user_uid) : ''">
        <template
          v-if="itemData.user.status != 'PENDING'"
          v-slot:rightSlot>
          <IconSvg
            iconName="check"
            :size="18"
            :iconColor="itemData.user.user_uid === viewModel.selectedChild ? '#BEA9ED' : '#A6A4A4'"/>
        </template>
      </ListItem>
    </div>
    <div v-else class="box_empty">
      <p class="desc_empty" v-html="$poData.gift_join_coupon_details_empty_desc"></p>
      <Button
        :text="$poData.gift_join_coupon_details_empty_btn"
        btnSize="small"
        btnStyle="secondary_tonal"
        @onClickBtn="pageMove('familyView')"/>
    </div>
    <strong class="tit_info">{{ $poData.gift_join_coupon_details_note }}</strong>
    <ul class="list_info">
      <li>선택한 자녀의 지갑에 포인트가 충전돼요</li>
      <li>가입 축하 포인트는 자녀 당 1번만 충전돼요</li>
      <li>포인트는 충전 즉시 현금처럼 사용할 수 있어요. 단, 계좌출금이나 환불은 불가능해요.</li>
    </ul>
    <Button
      btnStyle="primary"
      :disabled="!viewModel.selectedChild"
      :text="$poData.gift_join_coupon_details_btn"
      @onClickBtn="viewModel.onClickGiftApply()"/>
  </Layer>
</template>

<script>
import Layer from '@/components/layout/popup/Layer';
import Badge from '@/components/common/badge/Badge';
import ListItem from '@/components/common/list/ListItem';
import IconSvg from '@/components/common/icon/IconSvg';
import Button from '@/components/common/button/Button';
// mixins
import InterFaceMixin from '@/mixins/InterFaceMixin';
// viewModel
import CouponGiftDetailViewModel from '@/views/coupon/viewModel/CouponGiftDetailViewModel'

export default {
  name: 'CouponGiftDetail',
  mixins: [ InterFaceMixin ],
  components:{
    Layer,
    Badge,
    ListItem,
    IconSvg,
    Button,
  },
  props:{
    couponData : Object
  },
  beforeMount(){
    this.viewModel.init(this.couponData);
    // 선물하기 팝업닫기
    const onClosePopup = () => { this.$emit('onClickClose'); }
    this.viewModel.onClosePopup = onClosePopup;
    // 쿠폰 목록 불러오기
    const getCouponList = () => { this.$emit('getCouponList'); }
    this.viewModel.getCouponList = getCouponList;
  },
  data(){
    return{
      viewModel: new CouponGiftDetailViewModel(),
    }
  },
}
</script>

<style scoped>
.page_detail >>> .body_drop{position:relative;padding-bottom:104px}

.page_detail .desc_guide{font-weight:700;font-size:20px;line-height:28px;color:#000}

.box_coupon{overflow:hidden;margin-top:24px;padding:12px;border-radius:4px;background-color:#F3F1F1}
.box_coupon .badge_comm{float:left}
.box_coupon .tit_coupon{display:block;overflow:hidden;padding:4px 0 4px 8px;font-weight:500;font-size:12px;line-height:16px;color:#333;white-space:nowrap;text-overflow:ellipsis}
.box_coupon .desc_point{float:right;font-weight:700;font-size:16px;line-height:24px;color:#8F69DD}

.box_empty{margin-top:16px;padding:16px;border:1px dashed #A6A4A4;text-align:center}
.box_empty .desc_empty{font-weight:500;font-size:14px;line-height:22px;color:#999}
.box_empty .btn_small{margin-top:12px}

.tit_info{display:block;margin-top:16px;font-weight:700;font-size:16px;line-height:24px;color:#999}
.list_info{margin:7px 0 0 24px}
.list_info li{font-weight:500;font-size:14px;line-height:22px;color:#999}
.list_info,
.list_info li{list-style:disc}

.list_child{margin-top:16px}
.list_child .item_list{border:1px solid #E7E5E5;border-radius:4px}
.list_child .item_list >>> .desc_item{color:#666}
.list_child .item_list + .item_list{margin-top:16px}

.list_child .item_list.item_selected{border-color:#BEA9ED;background-color:#F0EAFA}
.list_child .item_list.item_disabled{border-color:#E7E5E5;background-color:#E7E5E5}

.page_detail .btn_primary{position:absolute;bottom:32px;left:20px;width:calc(100% - 40px)}
</style>