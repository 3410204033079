var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Layer',{staticClass:"page_detail",attrs:{"isFull":true},on:{"onClickClose":function($event){return _vm.$emit('onClickClose')}}},[_c('p',{staticClass:"desc_guide",domProps:{"innerHTML":_vm._s(_vm.$poData.gift_join_coupon_details_title)}}),_c('div',{staticClass:"box_coupon"},[_c('p',{staticClass:"desc_point"},[_vm._v(_vm._s(_vm._f("convertNumberToComma")(_vm.viewModel.couponData.point,false,'0','원')))]),_c('div',{staticClass:"area_overflow"},[_c('Badge',{attrs:{"value":"자녀용","type":"lightPurple"}}),_c('strong',{staticClass:"tit_coupon"},[_vm._v(_vm._s(_vm.viewModel.couponData.name))])],1)]),(_vm.viewModel.child_current_count > 0)?_c('div',{staticClass:"list_child"},_vm._l((_vm.viewModel.child_list),function(itemData,index){return _c('ListItem',{key:`childItem${index}`,class:{ 
        'item_selected' :  itemData.user.user_uid === _vm.viewModel.selectedChild,
        'item_disabled' :  itemData.user.status === 'PENDING',
      },attrs:{"padding":itemData.user.status === 'PENDING' ?  15 : 23,"thumbNail":{
        size: 40,
        url : itemData.user.profile_image_url
      },"title":itemData.family_member.title,"leftSubText":itemData.user.status === 'PENDING' ? _vm.$poData.gift_join_coupon_details_not_login_child : ''},on:{"onClickItem":function($event){itemData.user.status != 'PENDING' ? _vm.viewModel.onClickSeletedChild(itemData.user.user_uid) : ''}},scopedSlots:_vm._u([(itemData.user.status != 'PENDING')?{key:"rightSlot",fn:function(){return [_c('IconSvg',{attrs:{"iconName":"check","size":18,"iconColor":itemData.user.user_uid === _vm.viewModel.selectedChild ? '#BEA9ED' : '#A6A4A4'}})]},proxy:true}:null],null,true)})}),1):_c('div',{staticClass:"box_empty"},[_c('p',{staticClass:"desc_empty",domProps:{"innerHTML":_vm._s(_vm.$poData.gift_join_coupon_details_empty_desc)}}),_c('Button',{attrs:{"text":_vm.$poData.gift_join_coupon_details_empty_btn,"btnSize":"small","btnStyle":"secondary_tonal"},on:{"onClickBtn":function($event){return _vm.pageMove('familyView')}}})],1),_c('strong',{staticClass:"tit_info"},[_vm._v(_vm._s(_vm.$poData.gift_join_coupon_details_note))]),_c('ul',{staticClass:"list_info"},[_c('li',[_vm._v("선택한 자녀의 지갑에 포인트가 충전돼요")]),_c('li',[_vm._v("가입 축하 포인트는 자녀 당 1번만 충전돼요")]),_c('li',[_vm._v("포인트는 충전 즉시 현금처럼 사용할 수 있어요. 단, 계좌출금이나 환불은 불가능해요.")])]),_c('Button',{attrs:{"btnStyle":"primary","disabled":!_vm.viewModel.selectedChild,"text":_vm.$poData.gift_join_coupon_details_btn},on:{"onClickBtn":function($event){return _vm.viewModel.onClickGiftApply()}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }